<template>
    <div>
        <div class="col-12 d-flex mb-2">
            <b-form inline @submit.prevent class="ml-auto">
                <label class="mr-sm-2" for="breakdowns-type-filter">Type:</label>
                <b-form-select id="breakdowns-type-filter" class="mb-2 mr-sm-2 mb-sm-0" v-model="type" :options="filterTypes" @change="requests"></b-form-select>
            </b-form>
            <date-range-picker v-model="dateRange" opens="left" @update="requests" class="mr-2" :ranges="dateRanges">
                <template v-slot:input="picker" style="min-width: 350px;">
                    <span>{{ picker.startDate | date('MMM dd, yyyy') }} - {{ picker.endDate | date('MMM dd, yyyy') }}</span>
                    <i class="mdi mdi-calendar-blank" style="padding-left: 7px;"></i>
                </template>
            </date-range-picker>
            <b-button variant="light" @click="onExport" >Export</b-button>
        </div>
        <div class="col-12">
            <b-table id="breakdowns-win-rates-table" :items="tableOptions.items" :fields="tableOptions.columns" responsive class="text-center"
                show-empty :per-page="50" :current-page="tableOptions.currentPage">
                <template slot="empty">
                    No results found
                </template>
                <template v-slot:cell()="data">
                    <span v-if="parseFloat(data.value) < 0" style="color: red;">
                        {{data.value}}
                    </span>
                    <span v-else>
                        {{data.value}}
                    </span>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import { parseJSON, startOfMonth, endOfMonth, format, startOfWeek, endOfWeek, addMonths, startOfQuarter, endOfQuarter, addQuarters, startOfYear, endOfYear, addYears} from 'date-fns';
import DateRangePicker from 'vue-daterange-picker-light';
import 'vue-daterange-picker-light/dist/vue-daterange-picker-light.css';
import { map, clone } from 'lodash';
import { exportCsv } from '@/lib/Exporting';

export default {
    name: 'breakdown-win-rates',
    data() {
        let date = new Date();

        return {
            tableOptions: {
                columns: [],
                items: [],
                currentPage: 1
            },
            type: 'Market',
            filterTypes: [
                {
                    value: 'Market',
                    text: 'Market'
                },
                {
                    value: 'Bucket',
                    text: 'Bucket'
                }
            ],
            dateRange: {
                startDate: startOfWeek(date),
                endDate: endOfWeek(date)
            },
            dateRanges: {
                'This Week': [startOfWeek(date), endOfWeek(date)],
                'This Month': [startOfMonth(date), endOfMonth(date)],
                'Last Month': [startOfMonth(addMonths(date, -1)), endOfMonth(addMonths(date, -1))],
                'This Quarter': [startOfQuarter(date), endOfQuarter(date)],
                'Last Quarter': [startOfQuarter(addQuarters(date, -1)), endOfQuarter(addQuarters(date, -1))],
                'This Year': [startOfYear(date), endOfYear(date)],
                'Last Year': [startOfYear(addYears(date, -1)), endOfYear(addYears(date, -1))],
                'All Time': [new Date('2015-12-01'), endOfYear(date)]
            }
        };
    },
    components: {
        DateRangePicker
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('breakdownwinrates', this.onBreakdownWinRates);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('breakdownwinrates', this.onBreakdownWinRates);
                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'BreakdownWinRates',
                args: {
                    filterType: this.type,
                    startDate: format(this.dateRange.startDate, 'yyyy-MM-dd'),
                    endDate: format(this.dateRange.endDate, 'yyyy-MM-dd')
                }
            });
        },
        onBreakdownWinRates(event) {
            let columns = [
                {
                    key: 'market',
                    label: 'Markets'
                }
            ];

            for(let date of event.response.Dates) {
                columns.push({
                    key: date,
                    label: format(parseJSON(date), 'dd-MMM')
                });
            }

            columns.push({
                key: 'wtdReturn',
                label: 'WTD Return'
            });
            columns.push({
                key: 'wtdWinPercentage',
                label: 'WTD Win%'
            });
            columns.push({
                key: 'winnersLosers',
                label: 'W/L'
            });

            let totalReturns = {};

            for(let date of event.response.Dates) {
                totalReturns[date] = 0;
            }

            let totalWeekReturn = 0;

            let totalWinners = 0;
            let totalLosers = 0;

            let rows = [];
            for(let market in event.response.Breakdowns) {
                let item = event.response.Breakdowns[market];

                let row = {
                    market: market
                };

                let weekReturn = 0;
                
                let winners = 0;
                let losers = 0;

                let hasData = false;
                for(let date of event.response.Dates) {
                    if(item.hasOwnProperty(date)) {

                        if(item[date].Return !== 0) {
                            row[date] = `${item[date].Return.toFixed(3)}%`;
                        } else {
                            row[date] = '-';
                        }

                        weekReturn += item[date].Return;

                        winners += item[date].Winners;
                        losers += item[date].Losers;

                        totalReturns[date] += item[date].Return;
                        totalWeekReturn += item[date].Return;

                        totalWinners += item[date].Winners;
                        totalLosers += item[date].Losers;

                        hasData = true;
                    } else {
                        row[date] = '-';
                    }
                }

                if(hasData && weekReturn !== 0) {
                    row['wtdReturn'] = `${weekReturn.toFixed(3)}%`;
                } else {
                    row['wtdReturn'] = '-';
                }

                let totalWinnersLosers = winners + losers;

                if(totalWinnersLosers > 0) {
                    row['wtdWinPercentage'] = `${((winners / totalWinnersLosers) * 100).toFixed(1)}%`;
                } else {
                    row['wtdWinPercentage'] = '-';
                }

                row['winnersLosers'] = `${winners}/${losers}`;

                rows.push(row);
            }

            let totalRow = {
                market: 'Total',
                wtdReturn: `${totalWeekReturn.toFixed(3)}`,
                winnersLosers: `${totalWinners}/${totalLosers}`
            };

            for(let date in totalReturns) {
                totalRow[date] = `${totalReturns[date].toFixed(3)}%`;
            }

            let totalWinnersLosers = totalWinners + totalLosers;

            if(totalWinnersLosers > 0) {
                totalRow['wtdWinPercentage'] = `${((totalWinners / totalWinnersLosers) * 100).toFixed(1)}%`;
            } else {
                totalRow['wtdWinPercentage'] = '-';
            }

            rows.push(totalRow);

            this.tableOptions.columns = columns;
            this.tableOptions.items = rows;
        },
        onExport() {
            let headers = {};

            for(let column of this.tableOptions.columns) {
                headers[column.key] = column.label;
            }
            
            let items = map(this.tableOptions.items, clone);

            for(let item of items) {
                item['winnersLosers'] = `="${item['winnersLosers']}"`;
            }

            exportCsv(headers, items, 'Breakdowns');
        }
    }
}
</script>